<template>
  <div class="flex flex-col w-full pt-20 xl:h-screen">
    <Header is-checkout />

    <CheckoutTitle />

    <div class="container mx-auto flex flex-col flex-wrap justify-between flex-1 mt-[89px] xl:flex-row">
      <main class="w-full flex flex-col xl:items-center xl:w-3/5 xl:justify-start">
        <slot />

        <CheckoutCallToActionForGuaranteedPurchase class="xl:hidden" />
      </main>

      <aside class="flex justify-center w-full px-4 xl:px-0 left-0 bottom-0 xl:static xl:w-2/5 xl:justify-start">
        <CartResume mobile-resume-openned />
      </aside>
    </div>

    <Footer is-checkout />
  </div>
</template>
